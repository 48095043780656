import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('UserProfilePage.feedback')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/profile" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.product)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, { color: "primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('product')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_product_card, {
                        product: $setup.product,
                        hideDetails: "true"
                      }, null, 8, ["product"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, { color: "primary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('FeedbackPage.contactInfo')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.person,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.nickname')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: $setup.t('FeedbackPage.enterNickname'),
                        modelValue: $setup.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.name) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.mail,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.email')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: $setup.t('FeedbackPage.enterEmail'),
                        type: "email",
                        modelValue: $setup.email,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.email) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, { color: "primary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('FeedbackPage.feedback')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: "icon-lg",
                        icon: $setup.chatbubbleEllipsesOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.feedback')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_textarea, {
                        placeholder: $setup.t('FeedbackPage.enterFeedback'),
                        modelValue: $setup.feedback,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.feedback) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "submit-btn",
                expand: "block",
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.submitFeedback()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('submit')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}